.popup{

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0,0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
}


.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    font-size: 16px;
    max-width: 640px;
    background-color: #FFF;
    font-family : '"Helvetica Neue", Helvetica, sans-serif';
    box-shadow: 2px 2px 2px 2px #888888;

}

.popup-details{
    color: black;
    position: relative;
    text-align: left;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    max-width: 640px;
    background-color: white;
    font-family : '"Helvetica Neue", Helvetica, sans-serif';
    /* box-shadow: 0.5px 0.5px 0.5px 0.5px #888888; */
    border: solid 0.5px #888888;

}



.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0;
    border: none;
    background: none;
    /* background-color: red; */
    /* color: white; */

    
}

.popup-inner .pay-btn{
    background-color: #5433FF;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 40px;
    box-shadow: 1px 1px 1px 1px #999;
    font-family : '"Helvetica Neue", Helvetica, sans-serif';
    

}

.popup-inner .pay-btn:hover{
    background-color: #2200cc;
    color: white;
    font-size: 16px;
    padding: 12px 40px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #666;
    font-family : '"Helvetica Neue", Helvetica, sans-serif';
    
    
}

.popup-inner .btn-pay{
    cursor: pointer;
}

.popup-inner .btn-pay:hover{

    background-color: #E5E4E2;
   
}

.popup-inner .display{
    
    background-color: #E5E4E2;

}

.popup-inner .link{
    background-color: #2200cc;
    color: #2200cc;

}

a{
color: 	#6495ED;
background-color: transparent;
font-weight: bold;

}
  

a:hover {
color: darkblue;
background-color: transparent;
font-weight: bold;
cursor: pointer;

}

